<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <el-dropdown trigger="click">
                <el-button type="primary">
                    {{ $t('clients.menu') }} <i class="el-icon-arrow-down el-icon--right" />
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <router-link :to="{ name: 'clients_archived' }">
                        <el-dropdown-item>
                            {{ $t('clients.archived') }}
                        </el-dropdown-item>
                    </router-link>
                    <el-dropdown-item @click.native="exportToExcel">
                        {{ $t('common.export_to_excel') }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <div class="flex">
                <el-select
                    v-if="userIsBuildingCompany()"
                    v-model="selectedStatus"
                    class="mr-2"
                    clearable
                    :placeholder="$t('clients.status_filter')"
                    @change="filtersChanged"
                >
                    <el-option value="ongoing" :label="$t('clients.ongoing')" />
                    <el-option value="completed" :label="$t('clients.completed')" />
                    <el-option value="not_finished" :label="$t('clients.not_finished')" />
                    <el-option value="future_work" :label="$t('clients.future_work')" />
                </el-select>

                <ManagersDropdown
                    :inView="'clients_all'"
                    :cookieChoice="true"
                    :customClass="'mr-2'"
                    @selected="managerChanged"
                />

                <el-input v-model="searchQuery" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
            </div>
        </div>

        <Table :data="clientsData" @handleArchive="handleArchive" />

        <InitialLoader v-if="$wait.is('.initial_*')" />

        <div v-if="clientsData.length > 0" class="flex justify-center mt-6 font-thin w-full">
            <el-pagination
                class="font-bold"
                small
                layout="prev, pager, next, ->, sizes"
                :total="totalPages"
                :page-size="pageSize"
                :current-page="currentPage"
                :page-sizes="[100, 500, 1000]"
                @current-change="pageChanged"
                @size-change="sizeChanged"
            />
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        ManagersDropdown: () => import(/* webpackChunkName: "ManagersDropdown" */ '@/components/ManagersDropdown'),
        Table:            () => import(/* webpackChunkName: "ClientsAllTable" */ './ClientsAllTable'),
    },

    data() {
        return {
            selectedStatus: null,
            timer:          null,
            searchQuery:    '',
            searchQueryOld: '',
            totalPages:     1,
            pageSize:       100,
            currentPage:    1,
            clientsData:    [],
        };
    },

    watch: {
        searchQuery() {
            if (this.searchQuery !== this.searchQueryOld) {
                this.currentPage = 1;
                this.searchQueryOld = this.searchQuery;
            }
            clearTimeout(this.timer);
            this.timer = setTimeout(this.getAll, 400);
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_clients');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('clients.all_clients'));
        this.$store.commit('setActiveMenuItem', '1-1');
        const query = queryString.parse(window.location.search, { sort: false });
        this.pageSize = query.pageSize ? parseInt(query.pageSize, 10) : 100;
        this.currentPage = query.currentPage ? parseInt(query.currentPage, 10) : 1;

        this.getAll();
    },

    methods: {
        async getAll() {
            await axios.cancelAll();
            // axios.cancel('justRequest');
            const { manager } = queryString.parse(window.location.search, { sort: false });

            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients`, {
                requestId: 'justRequest',
                params:    {
                    pageSize: this.pageSize,
                    page:     this.currentPage,
                    manager,
                    search:   this.searchQuery,
                    status:   this.selectedStatus,
                },
            });

            this.clientsData = Object.freeze(data.data);
            this.totalPages = data.total;
            this.currentPage = data.current_page;

            this.$store.commit('setPageTitle', `${this.$t('clients.all_clients')} (${data.total})`);

            this.updateUrl();

            this.$wait.end('loading');
            this.$wait.end('loading.initial_clients');
        },

        pageChanged(currentPage) {
            this.currentPage = currentPage;
            this.updateUrl();
            this.getAll();
        },

        sizeChanged(pageSize) {
            this.pageSize = pageSize;
            this.updateUrl();
            this.getAll();
        },

        managerChanged() {
            this.currentPage = 1;
            this.updateUrl();
            this.getAll();
        },

        filtersChanged() {
            this.currentPage = 1;
            this.updateUrl();
            this.getAll();
        },

        handleArchive(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl: `${process.env.VUE_APP_CORE_API_URL}/clients/clients/${uuid}`,
            });
        },

        updateUrl() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.currentPage = this.currentPage;
            query.pageSize = this.pageSize;
            query.status = this.selectedStatus;

            this.$router.replace({ query }).catch(() => {});
        },

        exportToExcel() {
            const apiToken = Cookies.get('api_token');
            window.open(
                `${process.env.VUE_APP_CORE_API_URL}/clients/clients/export_to_excel?api_token=${apiToken}`,
                '_self'
            );
        }
    },
};
</script>
<style scoped>
    .el-input{
        width: 18rem;
    }
    .el-button--mini {
        padding: 5px 5px;
    }
</style>
<style>
    .el--mask {
        transition: opacity .2s;
    }
</style>
